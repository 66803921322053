
    import {onMounted, reactive, toRefs, computed, ref} from "vue";
    import EditorComponent from '@/components/editor/Editor.vue'
    import {message} from "ant-design-vue/es";
    import PageUtil from "@/utils/page_util";
    import UploadUtil from "@/utils/upload_util";
    import {baseUrl} from "@/utils/config";
    import apiOrder from "@/request/apis/api_logistics_order";
import { val } from "dom7";
    interface IState {
        list:any,
        searches:any,
        total:any,
        wantData:any,
        viseable:any,
        detailData:any,
        visableDetail:any
    }

    export default ({
        name: 'ArticleList',
        components: {EditorComponent},
        setup() {
            const state: IState = reactive({
                list:[],
                detailData:"",
                visableDetail:false,
                searches:{
                    page_no:PageUtil.pageNo,
                    page_size:PageUtil.pageSize,
                    type_id:'',
                    search:"",
                    timemin:"",
                    timemax:"",
                    plat_show:"",
                    mobile:"",
                    disable:""
                },
                total:0,
                wantData:'',
                viseable:false
            })

            const columns = [
                {
                    title: 'ID',
                    dataIndex: 'id',
                    key: 'id',
                    align:'center'
                },
                {
                    title: '标题',
                    dataIndex: 'title',
                    key: 'title',
                    align:'center'
                },
                {
                    title: '图片',
                    dataIndex: 'image',
                    key: 'image',
                    align:'center'
                },
                {
                    title: '数量',
                    dataIndex: 'num',
                    key: 'num',
                    align:'center'
                },
                {
                    title: '单位',
                    dataIndex: 'unit',
                    key: 'unit',
                    align:'center'
                },
                {
                    title: '类型',
                    dataIndex: 'category',
                    key: 'category',
                    align:'center'
                },
                {
                    title: '描述',
                    dataIndex: 'describe',
                    key: 'describe',
                    align:'center'
                },
                {
                    title: '联系人',
                    key: 'linkman',
                    dataIndex: 'linkman',
                    align:'center'
                },
                {
                    title: '电话',
                    key: 'phone',
                    dataIndex: 'phone',
                    align:'center'
                },
                {
                    title: '新旧',
                    key: 'oldnew',
                    dataIndex: 'oldnew',
                    align:'center'
                },
                {
                    title: '型号',
                    key: 'version',
                    dataIndex: 'version',
                    align:'center'
                },
                {
                    title: '最低价',
                    key: 'min_price',
                    dataIndex: 'min_price',
                    align:'center'
                },
                {
                    title: '最高价',
                    key: 'max_price',
                    dataIndex: 'max_price',
                    align:'center'
                },
                {
                    title: '地址',
                    key: 'address',
                    dataIndex: 'address',
                    align:'center'
                },
                {
                    title: '是否显示',
                    key: 'is_shop_show',
                    dataIndex: 'is_shop_show',
                    align:'center'
                },
                {
                    title: '创建时间',
                    key: 'createtime',
                    dataIndex: 'createtime',
                    align:'center'
                },
                {
                    title: '状态',
                    key: 'status',
                    dataIndex: 'status',
                    align:'center'
                },
                {
                    title: '操作',
                    key: 'action',
                    width: 150
                },
            ];

            const pagination = computed(() => ({
                total: state.total,
                current: state.searches.page_no,
                pageSize: state.searches.page_size,
                showTotal: (total: any) => `共${total}条数据`,
                defaultPageSize: 10,
                // pageSizeOptions: ['5', '10', '15', '20'], // 可不设置使用默认
                showSizeChanger: true, // 是否启用pageSize选择
                showQuickJumper: true, // 是否显示跳转窗
            }));

            const getList = async()=>{
                const params = {...state.searches}
                const res = await apiOrder.wantbuylist(params)
                if(res.error_code == 0){
                    const data:any = res.data
                    state.list = data.lists
                    state.total = data.count
                }else{
                    message.error(res.msg)
                }
            }

            const getdetail = async(val)=>{
                const params = {id:val.id}
                const res = await apiOrder.WaBntBuyShow(params)
                if(res.error_code == 0){
                   const data:any = res.data
                   state.detailData = data.data
                   state.visableDetail = true
                }else{
                    message.error(res.msg)
                }
            }

            const handleTableChange = (pages: any) => {
                state.searches.page_size = pages.pageSize
                state.searches.page_no = pages.current
                getList()
            };

            const handleOk = async () => {
                const params = {
                    id:state.wantData.id,
                    status:state.wantData.status,
                    is_shop_show:state.wantData.is_shop_show,
                    plat_show:state.wantData.plat_show,
                }
                const res = await apiOrder.wantbuyStatus(params)
                if (res) {
                    message.destroy()
                    if (res.error_code === 0) {
                        message.success(res.msg)
                        await getList()
                        state.viseable = false
                        return
                    }
                    message.error(res.msg)
                }
            }
            const onStatus = async (item,item2) => {
                 let datas = JSON.parse(JSON.stringify(item));
                state.wantData = datas
                state.viseable = true
            }
            const onSearch = () => {
                state.searches.page_no = 1
                state.searches.page_size = PageUtil.pageSize
                getList()
            }
            const onReset = () => {
                state.searches.page_no = 1
                state.searches.page_size = PageUtil.pageSize
                state.searches={
                    type_id:'',
                    search:"",
                    timemin:"",
                    timemax:"",
                    plat_show:"",
                    mobile:"",
                    disable:""
                },
                getList()
            }
            onMounted(() => {
                getList()
            })

            return {
                ...toRefs(state),
                getList,
                onSearch,
                onReset,
                onStatus,
                pagination,
                columns,
                handleOk,
                handleTableChange,
                getdetail
            }
        }
    })
